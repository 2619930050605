<template>
  <div class="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
    <div class="container">
      <div class="w-6/6 mx-auto my-10 bg-white py-5 rounded-md shadow-sm">
        <div class="text-center flex flex-col items-center">
          <div class="flex space-x-4"> 
            <img src="../../../assets/icons/Oonpay logo.png" class="img-fluid" height="80px" width="80px" alt="">
            <h1
            class="my-3 text-5xl font-semibold text-gray-700 dark:text-gray-200"
          >
            Let Get Started
          </h1>
          </div>
          <p class=" text-xl font-semibold text-gray-700">Who are you fundraising for</p>
        </div>
        <div class="row">
          <div class="col-md-6 p-5">
            <div class="p-10">
              <img src="https://dummyimage.com/600x400" alt="" />
              <a href="/individual-funding" class="text-center text-dark space-y-5 bg-gray-50 py-4">
                <h4 class="text-3xl text-center py-2 font-black">Individuals <br> Yourself /Someone Else</h4>

                <p class="text-xl">
                  Funds rasied will be deposited into a business or personal
                  bank account
                </p>
              </a>
            </div>
          </div>
          <div class="col-md-6 p-5">
            <div class="p-10">
              <img src="https://dummyimage.com/600x400" alt="" />
              <a href="#" class="text-center text-dark hover:underline bg-gray-50">
                <h4 class="text-3xl text-center py-2 font-black">
                  Charity & Non Profit Campaign
                </h4>

                <p class="text-xl">
                  Campaign Campaign donations will be
                  delivered to the your nonprofit organsation directly
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
a:hover {
  text-decoration: none;
}
</style>